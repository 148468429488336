import * as React from "react"

const MapComponent = () => {
  return (
    <div style={{ width: "100%", height: 300 }}>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d848.0975806060273!2d-52.335687971434524!3d-31.75983849837483!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9511b59f7699801b%3A0x355cafead2bde9b9!2zVW5uaXQgU29sdcOnw7VlcyBUZWNub2zDs2dpY2Fz!5e0!3m2!1sen!2sbr!4v1710864102454!5m2!1sen!2sbr"
        width="100%"
        height="100%"
        style={{ border: 0 }}
        loading="lazy"
        title="Google Map"
      ></iframe>
    </div>
  )
}

export default MapComponent
