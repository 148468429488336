import * as React from "react"
import { FaFacebookF } from "react-icons/fa"
import { FaInstagram } from "react-icons/fa"
import { FaLinkedinIn } from "react-icons/fa"

const SocialsComponent = () => {
  return (
    <div className="socials">
      <a href="https://www.facebook.com/unnit.com.br/" target="_blank" rel="noreferrer">
        <FaFacebookF />
      </a>
      <a href="https://www.instagram.com/insta.unnit/" target="_blank" rel="noreferrer">
        <FaInstagram />
      </a>
      <a href="https://www.linkedin.com/company/unnit/" target="_blank" rel="noreferrer">
        <FaLinkedinIn />
      </a>
    </div>
  )
}

export default SocialsComponent
