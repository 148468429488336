import * as React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Navbar, Nav, NavDropdown, Container, Row, Col } from "react-bootstrap"
import { useLocation } from "@reach/router"
import Socials from "./socials"
import { FiExternalLink, FiPhoneCall, FiSmartphone } from "react-icons/fi"

const NavbarComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        products {
          title
          slug
        }
      }
    }
  `)
  const pathname = useLocation().pathname
  return (
    <>
      <div className="header-wrapper">
        <div className="preheader">
          <Container>
            <Row>
              <Col className="phone" md={6} xs={8}>
                <FiSmartphone />
                <span>(53) 9.9139.8387</span>
                <FiPhoneCall className="ml-1 ml-md-2" />
                <span>(53) 3029.1500</span>
              </Col>
              <Col className="text-right socials">
                <Socials />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="navbar-wrapper">
          <Container>
            <Navbar variant="dark" expand="lg">
              <Link
                to="/"
                className="navbar-brand p-0 py-2"
                activeClassName="active"
              >
                <StaticImage
                  src="../../images/logo.png"
                  height={55}
                  alt="Logo"
                  placeholder="tracedSVG"
                />
              </Link>
              <Navbar.Toggle aria-controls="main-navbar" />
              <Navbar.Collapse id="main-navbar">
                <Nav className="ml-auto align-self-stretch align-items-center">
                  <Link
                    to="/sobre"
                    className="nav-link menu-item"
                    activeClassName="active"
                  >
                    Sobre Unnit
                  </Link>
                  <NavDropdown
                    title="Soluções"
                    id="navbar-dropdown"
                    className={
                      pathname.startsWith("/produto/") ? "menu-item active" : ""
                    }
                  >
                    {data.api.products.map((product, i) => {
                      return (
                        <Link
                          className="dropdown-item"
                          to={`/produto/${product.slug}`}
                          key={`product-${i}`}
                          activeClassName="active"
                        >
                          {product.title}
                        </Link>
                      )
                    })}
                  </NavDropdown>
                  <Link
                    to="/clientes"
                    className="nav-link menu-item"
                    activeClassName="active"
                  >
                    Clientes
                  </Link>
                  <Link
                    to="/contato"
                    className="nav-link menu-item"
                    activeClassName="active"
                  >
                    Contato
                  </Link>
                  <Nav.Link
                    href="https://loja.unnit.com.br"
                    target="_blank"
                    rel="noreferrer"
                    className="menu-item"
                  >
                    Loja Virtual <FiExternalLink />
                  </Nav.Link>
                  <Link
                    to="/blog"
                    className="nav-link menu-item"
                    activeClassName="active"
                  >
                    Blog
                  </Link>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Container>
        </div>
      </div>
    </>
  )
}

export default NavbarComponent
