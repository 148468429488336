import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, productStructuredData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description
  const siteUrl = "https://unnit.com.br"

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebPage",
    url: siteUrl,
    inLanguage: "pt-br",
    mainEntityOfPage: siteUrl,
    description: metaDescription,
    name: site.siteMetadata.title,
    author: {
      "@type": "Organization",
      "@id": site.siteMetadata.author,
    },
    image: {
      "@type": "ImageObject",
      url: "https://unnit.com.br/logo.png",
    },
  }
  const orgStructuredData = {
    "@context": "https://schema.org",
    "@type": "LocalBusiness",
    url: "https://unnit.com.br",
    name: "Unnit Soluções Tecnológicas",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+555330291500",
      contactType: "Atendimento",
    },
    telephone: "+555330291500",
    email: "atendimento@unnit.com.br",
    hasMap: "https://g.page/Unnit",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Pelotas",
      addressRegion: "Rio Grande do Sul",
      postalCode: "96020-220",
      streetAddress: "Marechal Deodoro, 1255",
    },
    sameAs: [
      "https://www.facebook.com/unnit.com.br/",
      "https://www.instagram.com/insta.unnit/",
      "https://www.linkedin.com/company/unnit/",
    ],
    openingHours: "Mo,Tu,We,Th,Fr, 09:00-12:00,14:00-18:00",
    image: {
      "@type": "ImageObject",
      url: "https://unnit.com.br/logo.png",
    },
  }
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={site.siteMetadata?.title || ""}
      titleTemplate={title ? `${title} | %s` : `%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
      <script type="application/ld+json">
        {JSON.stringify(orgStructuredData)}
      </script>
      {productStructuredData && (
        <script type="application/ld+json">
          {JSON.stringify(productStructuredData)}
        </script>
      )}
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `pt-br`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO
