import * as React from "react"
import PropTypes from "prop-types"
import Navbar from "./navbar"
import Footer from "./footer"
import Cta from "./cta"
import Map from "./map"
import { useLocation } from "@reach/router"
import CookieConsent from "react-cookie-consent"

import "../../assets/css/main.css"

const Layout = ({ children }) => {
  const pathname = useLocation().pathname

  // const newsLetter = (
  //   <>
  //     {new Date().getTime() > new Date("01/03/2022").getTime() && (
  //       <div
  //         dangerouslySetInnerHTML={{
  //           __html: `<script>
  //       (function(m,a,i,l,e,r){ m['MailerLiteObject']=e;function f(){
  //       var c={ a:arguments,q:[]};var r=this.push(c);return "number"!=typeof r?r:f.bind(c.q);}
  //       f.q=f.q||[];m[e]=m[e]||f.bind(f.q);m[e].q=m[e].q||f.q;r=a.createElement(i);
  //       var _=a.getElementsByTagName(i)[0];r.async=1;r.src=l+'?v'+(~~(new Date().getTime()/1000000));
  //       _.parentNode.insertBefore(r,_);})(window, document, 'script', 'https://static.mailerlite.com/js/universal.js', 'ml');          
  //       var ml_account = ml('accounts', '2866171', 'r0k5r1k7f8', 'load');
  //       </script>`,
  //         }}
  //       />
  //     )}
  //   </>
  // )

  return (
    <>
      <div
        className="d-flex flex-column min-vh-100"
        id="topo"
        main-pathname={pathname}
      >
        {/* {pathname === "/" && newsLetter} */}
        {!pathname.startsWith("/canais") && <Navbar />}
        <main className="flex-grow-1">{children}</main>
        {(pathname.startsWith("/produto") ||
          pathname.startsWith("/conteudo") ||
          pathname.startsWith("/sobre")) && <Cta />}
        {(pathname === "/" || pathname.startsWith("/contato")) && <Map />}
        {!pathname.startsWith("/canais") && <Footer />}
      </div>
      <CookieConsent
        disableStyles={true}
        location="bottom"
        buttonText="Ok!"
        debug={false}
        buttonClasses="btn btn-sm btn-tertiary mb-2 mb-md-0"
        contentClasses="text-center cookies-content"
        containerClasses="justify-content-center cookies-container"
      >
        Ao continuar sua navegação neste site, você concorda com o uso de
        Cookies.
      </CookieConsent>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
