import * as React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import Socials from "./socials"

const FooterComponent = () => {
  return (
    <footer>
      <Container>
        <Row className="py-3 align-items-center">
          <Col xs={3} className="text-center h-100 pt-1">
            <a href="#topo">
              <StaticImage
                src="../../images/logo_footer.png"
                height={60}
                alt="Logo"
                placeholder="tracedSVG"
              />
            </a>
          </Col>
          <Col>
            <Row className="text-left text-md-right">
              <Col className="pl-0">
                <span>Siga-nos nas redes sociais</span>
              </Col>
              <Col md={2} className="pl-0">
                <Socials />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default FooterComponent
